import React, { useState, useEffect,  } from 'react';
import axios from 'axios';
import BatchTable from '../Batch Table/BatchTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleProduct } from '../types/interfaces';




interface ProcessProductProps {
  onComplete?: () => void;
}

// WebSocket message interface
interface WebSocketMessage {
  ean: string;
  status: 'complete' | 'error';
  error?: string;
  product?: SingleProduct;
}

// Component props interface
interface ProcessProductProps {
  onComplete?: () => void;
}

const ProcessSingleProduct: React.FC<ProcessProductProps> = ({ onComplete }) => {
  const [status, setStatus] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedProduct, setProcessedProduct] = useState<SingleProduct | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Get EAN from URL parameters
  const searchParams = new URLSearchParams(location.search);
  const eanFromUrl = searchParams.get('ean');

  // Handle navigation if no EAN
  useEffect(() => {
    if (!eanFromUrl) {
      navigate('/batch');
    }
  }, [eanFromUrl, navigate]);

  // Effect to automatically start processing if EAN is in URL
  useEffect(() => {
    const processProduct = async (ean: string) => {
      const token = localStorage.getItem('token');
      if (!token) {
        setStatus('No authentication token found');
        return;
      }
  
      setIsProcessing(true);
      setStatus('Processing...');
  
      try {
        await axios.post(
          'https://toolex.herweck.de/api/process-product/',
          { ean },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        
        setStatus('Task started. Waiting for results...');
      } catch (error) {
        setStatus(`Error starting process: ${error instanceof Error ? error.message : 'Unknown error'}`);
        setIsProcessing(false);
      }
    };

    if (eanFromUrl) {
      processProduct(eanFromUrl);
    }
  }, [eanFromUrl]);

  // WebSocket connection
  useEffect(() => {
    console.log('Connecting to WebSocket...');
    const ws = new WebSocket('wss://toolex.herweck.de/ws/product-notifications/');

    ws.onopen = () => {
      console.log('WebSocket Connected');
    };

    ws.onmessage = (event) => {
      const data: WebSocketMessage = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);
      
      if (data.status === 'complete') {
        setStatus(`Product ${data.ean} processed successfully!`);
        setIsProcessing(false);
        if (data.product) {
          setProcessedProduct(data.product);
          console.log('Processed product data:', data.product);
        }
        if (onComplete) onComplete();
      } else if (data.status === 'error') {
        setStatus(`Error processing ${data.ean}: ${data.error}`);
        setIsProcessing(false);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setStatus('WebSocket connection error');
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      console.log('Cleaning up WebSocket');
      ws.close();
    };
  }, [onComplete]);

  // If no EAN, return null (navigation will handle redirect)
  if (!eanFromUrl) {
    return null;
  }

  // Render component
  return (
    <div className="p-4 max-w-7xl mx-auto">
      {isProcessing && (
        <div className="flex flex-col items-center justify-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mb-4"></div>
          <p className="text-lg text-gray-700">{status}</p>
        </div>
      )}

      {status && !isProcessing && (
        <div className={`mb-4 p-3 rounded-md ${
          status.includes('Error') 
            ? 'bg-red-50 text-red-700' 
            : status.includes('success') 
              ? 'bg-green-50 text-green-700' 
              : 'bg-blue-50 text-blue-700'
        }`}>
          {status}
        </div>
      )}

      {processedProduct && (
        <BatchTable<SingleProduct>
          mode="single"
          data={[processedProduct]}
          batchName={null}
          notFoundData={[]}
        />
      )}
    </div>
  );
};

export default ProcessSingleProduct;