import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { FileDown, X } from 'lucide-react';

interface Provider {
    provider_name: string | null;
    provider_id: string | null;
}

interface Classification {
    volume: string;
    amazon_margin: string;
    idealo_margin: string;
    online_since: string;
    avg_monthly_sold: string;
    avg_review: string;
    review_count: string;
    sub_category_rank: string;
    total_offers_idealo: string;
    color: string;
}

interface BatchProduct {
    id: number;
    EAN: string;
    ASIN: string | null;
    Title: string | null;
    manufacturer: string | null;
    color: string | null;
    Package_Volume: number | null;
    Package_Weight: number | null;
    Avg_Monthly_Sold: number | null;
    Review_Count: number | null;
    Avg_Review: number | null;
    Current_Amazon_Margin_EUR: number | null;
    Best_Margin_Idealo_EUR: number | null;
    Total_Offers_Idealo: number | null;
    Online_Since: string | null;
    Root_Category_Name: string | null;
    Sub_Category_Name: string | null;
    Latest_Sales_Rank_Sub_Category: number | null;
    volume_category: string;
    classifications: Classification;
    score: number;
    alpha_plan_article_id: string | null;
    alpha_plan_article_representative : string | null;
    All_Prices_Idealo: string | number[] | null;
    idealo_response: string | null;
    current_offer: CurrentOffer | null;  // Batch specific
}

interface SingleProduct {
    id: number;
    EAN: string;
    ASIN: string | null;
    Title: string | null;
    manufacturer: string | null;
    color: string | null;
    Package_Volume: number | null;
    Package_Weight: number | null;
    Avg_Monthly_Sold: number | null;
    Review_Count: number | null;
    Avg_Review: number | null;
    Current_Amazon_Margin_EUR: number | null;
    Best_Margin_Idealo_EUR: number | null;
    Total_Offers_Idealo: number | null;
    Online_Since: string | null;
    Root_Category_Name: string | null;
    Sub_Category_Name: string | null;
    Latest_Sales_Rank_Sub_Category: number | null;
    volume_category: string;
    classifications: Classification;
    score: number;
    alpha_plan_article_id: string | null;
    alpha_plan_article_representative : string | null;
    All_Prices_Idealo: string | number[] | null;
    idealo_response: string | null;

    // Single product specific
    alpha_plan_ek_price : number | null;
    Current_Amazon_Margin_EUR_AlphaPlan_EK: number | null;  
    Current_Idealo_Margin_EUR_AlphaPlan_EK: number | null;
    Current_Amazon_Margin_Percentage_AlphaPlan_EK  : number | null;
    Current_Idealo_Margin_Percentage_AlphaPlan_EK : number | null;
}


interface CurrentOffer {
    id: number;
    status: string;
    price: number | null;
    quantity: number | null;
    provider: Provider | null;
    Current_Amazon_Margin_EUR: number | null;
    Current_Idealo_Margin_EUR: number | null;
    Current_Idealo_Margin_Percentage: number | null;
    Current_Amazon_Margin_Percentage: number | null;
}

interface ExcelExportDialogProps {
    filteredProducts: (BatchProduct | SingleProduct)[];  // Updated product types
    batchName: string | null;
    columnVisibility: Record<string, boolean>;
    apiUrl?: string;
    className?: string;
}

const ExcelExportButton: React.FC<ExcelExportDialogProps> = ({
    filteredProducts,
    batchName,
    columnVisibility,
    apiUrl = 'https://toolex.herweck.de/api/export-excel/',
    className = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleExportExcel = async (exportOnlyApproved: boolean) => {
        try {
            const token = localStorage.getItem('token');
            
            // Type guard to check for BatchProduct
            const isBatchProduct = (product: BatchProduct | SingleProduct): product is BatchProduct => 
                'current_offer' in product;
    
            // For Ja button, filter products with status Ja
            let dataToExport = exportOnlyApproved 
                ? filteredProducts.filter(product => 
                    isBatchProduct(product) && product.current_offer?.status === "Ja"
                  )
                : filteredProducts;
    
            // Remove status column when only exporting Ja products or when not dealing with batch products
            let columnsToExport = { ...columnVisibility };
            if (exportOnlyApproved || !filteredProducts.some(isBatchProduct)) {
                delete columnsToExport.status;
            }
    
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    data: dataToExport,
                    batchName: batchName,
                    columns: columnsToExport
                }),
            });
    
            if (response.ok) {
                let filename = `${batchName}.xlsx`;
                const contentDisposition = response.headers.get('Content-Disposition');
                
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1].replace(/['"]/g, '');
                    }
                }
    
                const blob = await response.blob();
                const excelBlob = new Blob([blob], { 
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                
                saveAs(excelBlob, filename);
                setIsOpen(false);
            } else {
                const errorText = await response.text();
                console.error('Export failed:', errorText);
                throw new Error(`Export failed: ${errorText}`);
            }
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            throw error;
        }
    };
    

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className={`px-4 py-2 rounded-md bg-green-600 text-white hover:bg-green-700 transition-colors duration-200 flex items-center space-x-2 ${className}`}
            >
                <FileDown className="w-5 h-5" />
                <span>Export to Excel</span>
            </button>

            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
                        {/* Close button */}
                        <button 
                            onClick={() => setIsOpen(false)}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        >
                            <X className="w-5 h-5" />
                        </button>

                        {/* Dialog header */}
                        <div className="mb-6">
                            <h2 className="text-lg font-semibold text-gray-900">
                                Wählen Sie eine Exportoption
                            </h2>
                        </div>

                        {/* Dialog content */}
                        <div className="space-y-4">
                            <button
                                onClick={() => handleExportExcel(true)}
                                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
                            >
                                Exportieren nur Ja
                            </button>
                            <button
                                onClick={() => handleExportExcel(false)}
                                className="w-full px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-200"
                            >
                                Exportieren Alle
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ExcelExportButton;