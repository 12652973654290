import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Header from '../Header';

import { useNavigate } from 'react-router-dom';


type BatchStatus = 'In Arbeit' | 'Erledigt';

interface Batch {
  name: string;
  status: BatchStatus;
  processing_status: string;
  scraped_at: string;
  batch_size: number;
}

interface WebSocketMessage {
  batch_name: string;
  status: 'complete' | 'error';
}

const BatchListOverview: React.FC = () => {
  const [batches, setBatches] = useState<Batch[]>([]);
  const [filteredBatches, setFilteredBatches] = useState<Batch[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [processingBatches, setProcessingBatches] = useState(new Set<string>());

  const navigate = useNavigate();
  const handleBatchClick = (batchName: string) => {
    navigate(`/batch?selected=${encodeURIComponent(batchName)}`);
  };

  const getStatusColor = (status: BatchStatus) => {
    switch (status) {
      case 'In Arbeit': return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'Erledigt': return 'bg-green-100 text-green-800 border-green-200';
      default: return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  const fetchBatchesWithStatus = useCallback(async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      setError('No authentication token found');
      setLoading(false);
      return;
    }

    try {
      const namesResponse = await axios.get<string[]>('https://toolex.herweck.de/api/batch-names/', {
        headers: {
          Authorization: `Token ${token}`,
        },
        params: {
          type: 'all'
        }
      });

      const batchesWithStatus = await Promise.all(
        namesResponse.data
          .sort((a, b) => a.localeCompare(b))
          .map(async (batchName) => {
            try {
              const statusResponse = await axios.get('https://toolex.herweck.de/api/update-batch-status/', {
                params: { batch_name: batchName },
                headers: {
                  Authorization: `Token ${token}`,
                }
              });

              if (statusResponse.data.processing_status === 'scraping') {
                setProcessingBatches(prev => new Set(prev).add(batchName));
              } else {
                setProcessingBatches(prev => {
                  const newSet = new Set(prev);
                  newSet.delete(batchName);
                  return newSet;
                });
              }
              console.log('Status response:', statusResponse.data); 

              return {
                name: batchName,
                status: statusResponse.data.status,
                processing_status: statusResponse.data.processing_status,
                scraped_at: statusResponse.data.scraped_at,
                batch_size: statusResponse.data.batch_size
              };
            } catch (error) {
              console.error(`Error fetching status for batch ${batchName}:`, error);
              return {
                name: batchName,
                status: 'In Arbeit' as BatchStatus,
                processing_status: 'Failed to fetch status',
                scraped_at: null,
                batch_size: 0
              };
            }
          })
      );

      setBatches(batchesWithStatus);
      setFilteredBatches(batchesWithStatus);
    } catch (error) {
      setError('Failed to fetch batch data');
      console.error('Error fetching batches:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSingleBatchStatus = async (batchName: string) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const statusResponse = await axios.get('https://toolex.herweck.de/api/update-batch-status/', {
        params: { batch_name: batchName },
        headers: {
          Authorization: `Token ${token}`,
        }
      });

      setBatches(prev => prev.map(batch => 
        batch.name === batchName 
          ? {
              ...batch,
              status: statusResponse.data.status,
              processing_status: statusResponse.data.processing_status,
              scraped_at: statusResponse.data.scraped_at
            }
          : batch
      ));

      if (statusResponse.data.processing_status !== 'scraping') {
        setProcessingBatches(prev => {
          const newSet = new Set(prev);
          newSet.delete(batchName);
          return newSet;
        });
      }
    } catch (error) {
      console.error(`Error updating batch ${batchName} status:`, error);
    }
  };

  const reprocessBatch = async (batchName: string) => {
    const token = localStorage.getItem('token');
    setProcessingBatches(prev => new Set(prev).add(batchName));
    setError(null);

    const batch = batches.find(b => b.name === batchName);
    if (batch && batch.batch_size > 300) {
      const userConfirmed = window.confirm(`The batch size is ${batch.batch_size}. Are you sure you want to reprocess this batch?`);
      if (!userConfirmed) {
        setProcessingBatches(prev => {
          const newSet = new Set(prev);
          newSet.delete(batchName);
          return newSet;
        });
        return;
      }
    }

    try {
      const response = await axios.post(
        'https://toolex.herweck.de/api/reprocess-batch/',
        { batch_name: batchName },
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );

      if (response.status !== 200) {
        setError(`Failed to start reprocessing of batch '${batchName}'`);
        setProcessingBatches(prev => {
          const newSet = new Set(prev);
          newSet.delete(batchName);
          return newSet;
        });
      }
    } catch (error) {
      setError(`Failed to reprocess batch ${batchName}`);
      console.error('Error reprocessing batch:', error);
      setProcessingBatches(prev => {
        const newSet = new Set(prev);
        newSet.delete(batchName);
        return newSet;
      });
    }
  };

  useEffect(() => {
    console.log('Connecting to WebSocket...');
    const ws = new WebSocket('wss://toolex.herweck.de/ws/batches/');

    ws.onopen = () => {
      console.log('WebSocket Connected');
    };

    ws.onmessage = (event) => {
      const data: WebSocketMessage = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);
      
      if (data.batch_name && (data.status === 'complete' || data.status === 'error')) {
        // Update the specific batch's status
        fetchSingleBatchStatus(data.batch_name);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      console.log('Cleaning up WebSocket');
      ws.close();
    };
  }, []);

  // Initial fetch of batches
  useEffect(() => {
    fetchBatchesWithStatus();
  }, [fetchBatchesWithStatus]);

  // Search filter
  useEffect(() => {
    const filtered = batches.filter(batch =>
      batch.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBatches(filtered);
  }, [searchTerm, batches]);

  if (loading && processingBatches.size === 0) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="p-4 space-y-4">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
            <button
              onClick={() => setError(null)}
              className="absolute top-0 right-0 p-3"
            >
              ✕
            </button>
          </div>
        )}

        <div className="flex justify-between items-center">
          <div className="relative w-72">
            <input
              type="text"
              placeholder="Search batches..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            )}
          </div>
          <button
            onClick={() => fetchBatchesWithStatus()}
            className="px-4 py-2 text-sm text-blue-600 hover:text-blue-800 flex items-center gap-2"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            Refresh
          </button>
          <div className="text-sm text-gray-500">
            Showing {filteredBatches.length} of {batches.length} batches
          </div>
        </div>

        <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Batch Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Batch Size
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Bearbeitungs Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Processing Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Updated
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {filteredBatches.map((batch) => (
              <tr key={batch.name} className="hover:bg-gray-50" onClick={() => handleBatchClick(batch.name)}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {batch.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {batch.batch_size }
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-3 py-1 text-sm rounded-full inline-flex ${getStatusColor(batch.status)}`}>
                    {batch.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {batch.processing_status}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {batch.scraped_at
                    ? new Date(batch.scraped_at).toLocaleString('de-DE')
                    : 'Not available'
                  }
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // This prevents the row click event from firing
                      reprocessBatch(batch.name);
                    }}
                    disabled={batch.processing_status === 'scraping' || processingBatches.has(batch.name)}
                    className={`px-3 py-1 rounded-md text-sm font-medium 
                      ${batch.processing_status === 'scraping' || processingBatches.has(batch.name)
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                      }`}
                  >
                    {batch.processing_status === 'scraping' || processingBatches.has(batch.name) ? (
                      <span className="flex items-center space-x-1">
                        <svg className="animate-spin h-4 w-4 mr-1" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      'Reprocess'
                    )}
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
            <tfoot className="bg-gray-50">
              <tr>
                <td colSpan={5} className="px-6 py-3 text-sm">
                  <div className="flex space-x-4">
                    <span>Total: {filteredBatches.length}</span>
                    <span>In Arbeit: {filteredBatches.filter(b => b.status === 'In Arbeit').length}</span>
                    <span>Erledigt: {filteredBatches.filter(b => b.status === 'Erledigt').length}</span>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default BatchListOverview;