import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BatchSelector from './Batch Table/BatchSelector';
import BatchTable from './Batch Table/BatchTable';
import BatchSummaryComponent from './Batch Table/BatchSummaryComponent';
import ExcelUploader from './Batch Table/ExcelUploader';
import TextUploader from './Batch Table/TextUpload';
import ScoreComponent from './ScoreComponent';
import Header from './Header';
import UploadModal from './UploadModle';
import { XIcon } from 'lucide-react';

import { useSearchParams } from 'react-router-dom';

import { BatchProduct,Batch } from './types/interfaces';


const ParentComponent: React.FC = () => {
    const [selectedBatch, setSelectedBatch] = useState<string | null>(null);
    const [batches, setBatches] = useState<string[]>([]);
    const [selectedBatchData, setSelectedBatchData] = useState<Batch | null>(null);
    const [isExcelModalOpen, setIsExcelModalOpen] = useState(false);
    const [isTextModalOpen, setIsTextModalOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const [isScoreOpen, setIsScoreOpen] = useState<boolean>(false);
    const [batchLoading, setBatchLoading] = useState<boolean>(false); 
    const token = localStorage.getItem('token');

    const [searchParams] = useSearchParams();

    const [data, setData] = useState<BatchProduct[]>([]);
    const [notFoundData, setNotFoundData] = useState<BatchProduct[]>([]);

    useEffect(() => {
      const batchFromUrl = searchParams.get('selected');
      if (batchFromUrl) {
        setSelectedBatch(batchFromUrl);
      }
    }, [searchParams]);

    const handleDataUpdate = (newData: BatchProduct[], newNotFoundData: BatchProduct[]) => {
        setData(newData);
        setNotFoundData(newNotFoundData);
    };

    // Fetch all batch names
    useEffect(() => {
        axios.get('https://toolex.herweck.de/api/batch-names/', {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: {
              type: 'In Arbeit'
            }
        })
        .then((response) => {
            setBatches(response.data);
        })
        .catch((error) => {
            console.error('Error fetching batch names: ', error);
        });
    }, []);

    // Fetch specific batch data when selected over the URL 
    useEffect(() => {
        if (selectedBatch) {
          setBatchLoading(true);
            axios.get(`https://toolex.herweck.de/api/batch/?batch_name=${encodeURIComponent(selectedBatch)}`, {
                headers: {
                    Authorization: `Token ${token}`,
                }
            })
            .then((response) => {
                setSelectedBatchData(response.data[0]);
                setBatchLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching batch details: ', error);
            });
        }
    }, [selectedBatch]);

    const handleScoreSubmit = () => {
        setIsScoreOpen(false);
    };

    return (
        <div>
        <Header />
  
        <div className="relative flex">
          {/* Sidebar */}
          <div
            className={`fixed top-0 left-0 h-full bg-gray-100 shadow-lg transition-transform duration-300 ease-in-out 
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 p-4 pt-16 space-y-4`}
          >
            <BatchSelector 
              batches={batches}
              onSelectBatch={(batch) => {
                setSelectedBatch(batch)
                setBatchLoading(true);
              }} 
            />
            
            {/* Upload Buttons */}
            <div className="space-y-3 mt-6">
              <button 
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md transition-colors duration-200"
                onClick={() => setIsExcelModalOpen(true)}
              >
                Upload Excel File
              </button>
              
              <button 
                className="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md transition-colors duration-200"
                onClick={() => setIsTextModalOpen(true)}
              >
                Upload Text Data
              </button>
            </div>
  
            <button
              className="w-full mt-4 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md transition-colors duration-200"
              onClick={() => setIsScoreOpen(true)}
            >
              Open Score Settings
            </button>
          </div>
  
          {/* Toggle Button */}
            <button 
            className="fixed top-4 left-4 bg-gray-300 text-gray-700 p-2 rounded-md shadow-md hover:bg-gray-400 focus:outline-none w-12 h-12 z-50 transition-colors duration-200 flex items-center justify-center"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
            {isSidebarOpen ? (
              <XIcon className="h-6 w-6" />
            ) : (
              <div className="space-y-1">
              <span className="block w-6 h-0.5 bg-gray-700"></span>
              <span className="block w-6 h-0.5 bg-gray-700"></span>
              <span className="block w-6 h-0.5 bg-gray-700"></span>
              </div>
            )}
            </button>
  
          {/* Main Content */}
          <div
              className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-8`}
            >
              {batchLoading ? (
                  <div className="flex justify-center items-center min-h-[60vh]">
                  <div className="relative">
                    <div className="animate-spin rounded-full h-20 w-20 border-4 border-gray-200 border-t-blue-500 border-l-blue-500"></div>
                    <div className="mt-4 text-center text-gray-600 font-medium">
                      Loading {selectedBatch}
                    </div>
                  </div>
                </div>
              ) : (
                selectedBatchData && (
                <BatchTable<BatchProduct>
                    mode="batch"
                    data={selectedBatchData.complete_products}
                    batchName={selectedBatch}
                    notFoundData={selectedBatchData.incomplete_products}
                    onDataUpdate={handleDataUpdate}
                />
                )
              )}
            </div>
        </div>
  
        {/* Upload Modals */}
        <UploadModal
          isOpen={isExcelModalOpen}
          onClose={() => setIsExcelModalOpen(false)}
          title="Upload Excel File"
        >
          <ExcelUploader onSuccess={() => setIsExcelModalOpen(false)} />
        </UploadModal>
  
        <UploadModal
          isOpen={isTextModalOpen}
          onClose={() => setIsTextModalOpen(false)}
          title="Upload Text Data"
        >
          <TextUploader onSuccess={() => setIsTextModalOpen(false)} />
        </UploadModal>
  
        {/* ScoreComponent Modal */}
        <UploadModal
          isOpen={isScoreOpen}
          onClose={() => setIsScoreOpen(false)}
          title="Score Settings"
        >
          <ScoreComponent onSubmit={handleScoreSubmit} />
        </UploadModal>
      </div>
    );
};

export default ParentComponent;