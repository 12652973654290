import React, { useState, useEffect, useRef } from 'react';
import BatchStatusSelector from './BatchStatusSelector';
import ExcelExportButton from '../Excel/ExcelExport';
import axios from 'axios';

import { BatchProduct, SingleProduct, BatchProductWithGalaxus, GalaxusResult } from '../types/interfaces';

import ColumnToggle from './ColumSettings';


// Union type for our product types
type ProductType = BatchProduct | SingleProduct | BatchProductWithGalaxus;

// Props interface that can handle both cases
interface ProductTableProps<T extends ProductType> {
    data: T[];
    mode: 'batch' | 'single';
    batchName?: string | null;  // Optional for batch mode
    notFoundData?: T[];         // Optional for batch mode
    onDataUpdate?: (newData: T[], newNotFoundData: T[]) => void;  // Optional for batch mode
}

const CLASSIFICATION_COLORS = {
    'good': 'bg-green-500 text-white',
    'medium': 'bg-yellow-500 text-black',
    'bad': 'bg-red-500 text-white',
    'Unknown': 'bg-gray-300 text-gray-900'
};

const STATUS_COLORS = {
    'Nicht bearbeitet': 'bg-gray-300 text-gray-900',
    'Ja': 'bg-green-500 text-white',
    'Nein': 'bg-red-500 text-white',
    'WVL': 'bg-yellow-500 text-black',
    'EK-Neu Verhandeln': 'bg-purple-500 text-white'
};

const BatchTable = <T extends ProductType>({ 
    data, 
    mode, 
    batchName, 
    notFoundData = [], 
    onDataUpdate 
}: ProductTableProps<T>) => {
    const [showToggle, setShowToggle] = useState(true);

    const [showNotFound, setShowNotFound] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc'); // Default to descending
    const [scoreFilter, setScoreFilter] = useState<string>('');
    const [filteredProducts, setFilteredProducts] = useState<T[]>([]);
    const [isColumnSettingsOpen, setIsColumnSettingsOpen] = useState(false);

    // Function to reprocess data for Galaxus Scraping 
    const wsRef = useRef<WebSocket | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [results, setResults] = useState<GalaxusResult | null>(null);
    const [status, setStatus] = useState('');
    
    const [baseProducts, setBaseProducts] = useState<T[]>([]); // Store the full set of products

    useEffect(() => {
        wsRef.current = new WebSocket('wss://toolex.herweck.de/ws/galaxus-process/');

        wsRef.current.onopen = () => {
            console.log('WebSocket Connected');
            setStatus('Connected to WebSocket');
        };

        wsRef.current.onmessage = (event) => {
            const data: GalaxusResult = JSON.parse(event.data);
            console.log('Received WebSocket message:', data);
        
            if (data.status === 'complete') {
                setStatus(`Batch ${data.batch_name} processed successfully!`);
                setIsProcessing(false);
                setResults(data);
                setShowToggle(false);
                if (data.data) {
                    // Update column visibility based on new data
                    setColumnVisibility(prev => ({
                        ...prev,
                        Galaxus_CH_Margin_Eur: data.data?.some(product => 
                            product.current_offer && 'Galaxus_CH_Margin' in product.current_offer
                        ) || false,
                        Galaxus_DE_Margin_Eur: data.data?.some(product => 
                            product.current_offer && 'Galaxus_DE_Margin' in product.current_offer
                        ) || false
                    }));
        
                    // Update the filtered products
                    setFilteredProducts((prevProducts: T[]) => {
                        const updatedProducts = [...prevProducts];
                        data.data?.forEach(newProduct => {
                            const index = updatedProducts.findIndex(p => p.EAN === newProduct.EAN);
                            if (index !== -1) {
                                updatedProducts[index] = newProduct as T;
                            }
                        });
                        return updatedProducts;
                    });
                }
            } else if (data.status === 'error') {
                setStatus(`Error processing batch ${data.batch_name}: ${data.error}`);
                setIsProcessing(false);
                setResults(data);
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
            setStatus('WebSocket connection error');
        };

        wsRef.current.onclose = () => {
            console.log('WebSocket disconnected');
            setStatus('WebSocket disconnected');
        };

        return () => {
            if (wsRef.current) {
            wsRef.current.close();
            }
        };
        }, []);
    
    const handleGalaxusScraping = (e: React.FormEvent) => {
        e.preventDefault();
        
        const eanList = filteredProducts.map(product => product.EAN);
        
        if (eanList.length === 0) {
            setStatus('Please enter at least one EAN');
            return;
        }

        if (eanList.length > 25) {
            if (!window.confirm(`You have selected ${eanList.length} products. Are you sure you want to scrape?`)) {
                return;
            }
        }

        if (wsRef.current?.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({
                batch_name: batchName,
                eans: eanList,
            }));
            
            setIsProcessing(true);
            setResults(null);
            setStatus('Processing started...');
        } else {
            setStatus('WebSocket not connected');
        }
    };
    

    // Type guard to check if the product is a BatchProductWithGalaxus

    const isBatchProductWithGalaxus = (product: BatchProduct | SingleProduct | BatchProductWithGalaxus): product is BatchProductWithGalaxus => 
        'current_offer' in product

    useEffect(() => {
        const filtered = data.filter(product => !isBatchProductWithGalaxus(product));
        setFilteredProducts(filtered);
    }, [data]);

    const isBatchProduct = (product: BatchProduct | SingleProduct | BatchProductWithGalaxus): product is BatchProduct => 
        'current_offer' in product;

    // Conditional Column Rendering depending on batch or single product
    const getColumnContent = (
        product: BatchProduct | SingleProduct | BatchProductWithGalaxus, 
        key: string,
        index: number  // Add index parameter
    ) => {
    
        switch(key) {
            case 'status':
                if (!isBatchProduct(product)) return null;
                return (
                    <div className="relative">
                        <button
                            onClick={() => setActiveDropdown(activeDropdown === index ? null : index)}
                            className={`px-2 py-1 rounded ${
                                STATUS_COLORS[product.current_offer?.status as keyof typeof STATUS_COLORS] || 
                                STATUS_COLORS['Nicht bearbeitet']
                            }`}
                        >
                            {product.current_offer?.status || 'Nicht bearbeitet'}
                        </button>
                    
                    {activeDropdown === index && (
                        <div className="absolute z-50 mt-1 bg-white border rounded-md shadow-lg">
                            {Object.keys(STATUS_COLORS).map((status) => (
                                <button
                                    key={status}
                                    onClick={() => handleStatusChange(product, status)}
                                    className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                        STATUS_COLORS[status as keyof typeof STATUS_COLORS]
                                    }`}
                                >
                                    {status}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            );
    
            case "EK_Preis":
                if (isBatchProduct(product)) {
                    return product.current_offer?.price ? `${formatValue(product.current_offer?.price, 'number')} €` : 'N/A';
                }
                return product.alpha_plan_ek_price ? `${formatValue(product.alpha_plan_ek_price, 'number')} €` : 'N/A';

            case 'Latest_Price_Amazon_EUR_Netto':
                return `${formatValue(product.Latest_Price_Amazon_EUR_Netto, 'number')} €`;
            
            case 'Latest_Price_Amazon_EUR_Brutto':
                return `${formatValue(product.Latest_Price_Amazon_EUR_Brutto, 'number')} €`;
    
            case 'Amazon_Margin_Eur':
                if (isBatchProduct(product)) {
                    return `${formatValue(product.current_offer?.Current_Amazon_Margin_EUR, 'number')} €`;
                }
                return `${formatValue(product.Current_Amazon_Margin_EUR_AlphaPlan_EK, 'number')} €`;
    
            case 'Amazon_Margin_Procent':
                if (isBatchProduct(product)) {
                    return `${formatValue(product.current_offer?.Current_Amazon_Margin_Percentage, 'number')}%`;
                }
                return `${formatValue(product.Current_Amazon_Margin_Percentage_AlphaPlan_EK, 'number')}%`;
    
            case 'Idealo_Margin_Eur':
                    const marginValue = isBatchProduct(product) 
                        ? product.current_offer?.Current_Idealo_Margin_EUR
                        : product.Current_Idealo_Margin_EUR_AlphaPlan_EK;
                
                    let idealoOffers: Array<{
                        shop_name: string;
                        total: number;
                        price: number;
                        shipping: number;
                        availability_text: string;
                    }> = [];
                
                    try {
                        if (product.idealo_response) {
                            const parsedResponse = JSON.parse(product.idealo_response);
                            if (parsedResponse.results?.[0]?.content?.offers) {
                                idealoOffers = [...parsedResponse.results[0].content.offers].sort(
                                    (a: { total: number }, b: { total: number }): number => a.total - b.total
                                );
                            }
                        }
                    } catch (error) {
                        console.error('Error parsing Idealo response:', error);
                    }
                
                    return (
                        <div >
                                <span 
               
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        setOpenIdealoEAN(openIdealoEAN === product.EAN ? null : product.EAN);
                                    }}
                                >
                                    {`${formatValue(marginValue, 'number')} €`}
                                </span>
                            
                            {openIdealoEAN === product.EAN && (
                                <>
                                    <div 
                                        className="fixed inset-0 bg-black bg-opacity-50 z-40"
                                        onClick={() => setOpenIdealoEAN(null)}
                                    />
                                    <div 
                                        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 
                                                bg-white rounded-lg shadow-xl p-4 w-[600px] max-h-[80vh]"
                                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                    >
                                        <div className="flex justify-between items-center mb-2">
                                            <div className="text-lg font-bold text-gray-800">Idealo Sellers</div>
                                            <button 
                                                onClick={() => setOpenIdealoEAN(null)}
                                                className="text-gray-500 hover:text-gray-700"
                                            >
                                                ✕
                                            </button>
                                        </div>
                                        {idealoOffers.length > 0 ? (
                                            <div className="overflow-y-auto max-h-[60vh]">
                                                {idealoOffers.map((offer, idx) => (
                                                    <div key={idx} className="flex justify-between items-center py-1 px-2 hover:bg-gray-50 text-sm">
                                                        <div className="text-gray-800 w-1/3 truncate">{offer.shop_name}</div>
                                                        <div className="flex gap-4">
                                                            <span>{formatValue(offer.price, 'number')}€</span>
                                                            <span>+{formatValue(offer.shipping, 'number')}€</span>
                                                            <span className="font-medium">{formatValue(offer.total, 'number')}€</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-gray-600">No sellers available</div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    );
            
            case 'Idealo_Margin_Procent':
                if (isBatchProduct(product)) {
                    return `${formatValue(product.current_offer?.Current_Idealo_Margin_Percentage, 'number')}%`;
                }
                return `${formatValue(product.Current_Idealo_Margin_Percentage_AlphaPlan_EK, 'number')}%`;
            
            // Case for Galaxus Margin
            case 'Galaxus_CH_Margin_Eur':
                if (isBatchProductWithGalaxus(product)) {
                    return `${formatValue(product.current_offer?.Galaxus_CH_Margin, 'number')} €`;
                }
                return 'N/A';

            case 'Galaxus_DE_Margin_Eur':
                if (isBatchProductWithGalaxus(product)) {
                    return `${formatValue(product.current_offer?.Galaxus_DE_Margin, 'number')} €`;
                }
                return 'N/A';
    
            case 'EAN':
                    return (
                        <div className="relative">
                            <span 
                                className="cursor-pointer"
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    setOpenEAN(openEAN === product.EAN ? null : product.EAN);
                                }}
                            >
                                {`${formatValue(product.EAN)}`}
                            </span>
                            
                            {openEAN === product.EAN && (
                                <div 
                                    className="absolute z-50 left-0 mt-1 p-2 bg-white border rounded-md shadow-lg min-w-[200px]"
                                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                                >
                                    <a 
                                        href={`https://www.galaxus.de/search?q=${product.EAN}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-blue-600 hover:text-blue-800"
                                    >
                                        Galaxus DE | CH
                                    </a>
                                    <a 
                                        href={`https://www.idealo.de/preisvergleich/MainSearchProductCategory.html?q=${product.EAN}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-blue-600 hover:text-blue-800"
                                    >
                                        Idealo
                                    </a>
                                    <a 
                                        href={`https://www.amazon.de/s?k=${product.EAN}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-blue-600 hover:text-blue-800"
                                    >
                                        Amazon
                                    </a>
                                    <a 
                                        href={`https://www.alza.cz/search.htm?exps=${product.EAN}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-blue-600 hover:text-blue-800"
                                    >
                                        Alza
                                    </a>
                                </div>
                            )}
                        </div>
                    );          
            case 'Title':
                return product.Title;            
            case 'Manufacturer':
                return formatValue(product.manufacturer);           
            case 'Herweck_Artikel_Nr':
                return product.is_archiviert ? 'archiviert' : product?.alpha_plan_article_id;
            case 'Herweck_Artikel_Vertreter':
                    const representative = product?.alpha_plan_article_representative;
                    if (representative) {
                        const initials = representative.split(' ').map(name => name[0]).join('');
                        const isCurrentUser = representative === username;
                        return (
                            <span className={isCurrentUser ? '' : 'text-red-500'}>
                                {initials}
                            </span>
                        );
                    }
                    return '';        
            case 'Color':
                return formatValue(product.color);           
            case 'Volume':
                    return (
                        <>
                            <span className="text-xs ml-1">({product.volume_category})</span>
                        </>
                    );
            case 'Online_Since':
                return formatValue(product.Online_Since, 'date');
            case 'Monthly_Sold':
                return formatValue(product.Avg_Monthly_Sold, 'number');
            case 'Reviews':
                return formatValue(product.Avg_Review, 'number');
            case 'Review_Count':
                return formatValue(product.Review_Count, 'number');
            case 'Sub_Category':
                return formatValue(product.Sub_Category_Name);
            case 'Category_Rank':
                return formatValue(product.Latest_Sales_Rank_Sub_Category, 'number');
            case 'Total_Offers_Idealo':
                return formatValue(product.Total_Offers_Idealo, 'number');
            case 'Score':
                return formatValue(product.score, 'number');
            default:
                return 'N/A';
        }
    };

    // Username 
    const [username, setUsername] = useState<string | null>(null);

    useEffect(() => {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');

        // Fetch the current user's profile
        axios.get('https://toolex.herweck.de/api/user-profile/', {
            headers: {
                Authorization: `Token ${token}`,  // Include token in the request
            }
        })
        .then((response) => {
            const { first_name, last_name } = response.data;
            setUsername(`${first_name} ${last_name}`);  // Set username as full name
            console.log(response.data);
        })
        .catch((error) => {
            console.error('Error fetching user profile:', error);
        });
    }, []);

    // links for the EAN open Button
    const [openEAN, setOpenEAN] = useState<string | null>(null);

    useEffect(() => {
        const closeOnClick = () => {
            setOpenEAN(null);
        };
        
        document.addEventListener('click', closeOnClick);
        return () => document.removeEventListener('click', closeOnClick);
    }, []);

    const [openIdealoEAN, setOpenIdealoEAN] = useState<string | null>(null);

    // Add the effect at component level too
    useEffect(() => {
        const closeIdealoPopup = () => {
            setOpenIdealoEAN(null);
        };
        
        document.addEventListener('click', closeIdealoPopup);
        return () => document.removeEventListener('click', closeIdealoPopup);
    }, []);
    
    // filter and sort the products
    useEffect(() => {
        // Get current products (either from data or previous filtered products with Galaxus data)
        const currentProducts = data;
        const productsToShow = showNotFound ? notFoundData : currentProducts;
        
        const sortedProducts = [...productsToShow].sort((a, b) => {
            return sortDirection === 'desc' 
                ? b.score - a.score 
                : a.score - b.score;
        });
    
        if (scoreFilter !== '') {
            const threshold = parseFloat(scoreFilter) / 10;
            const filtered = sortedProducts.filter(product => product.score >= threshold);
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(sortedProducts);
        }
    }, [data, notFoundData, showNotFound, sortDirection, scoreFilter]);

    const handleScoreFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || (/^\d+$/.test(value) && parseInt(value) <= 10)) {
            setScoreFilter(value);
        }
    };

    const getClassificationColor = (classification: string) => {
        return CLASSIFICATION_COLORS[classification as keyof typeof CLASSIFICATION_COLORS] || CLASSIFICATION_COLORS['Unknown'];
    };

    // Helper function to format values
    const formatValue = (value: any, type: 'number' | 'date' | 'string' = 'string') => {
        if (value === null || value === undefined) return 'N/A';
        switch (type) {
            case 'number':
                return typeof value === 'number' ? value.toFixed(2) : 'N/A';
            case 'date':
                return value ? new Date(value).toLocaleDateString() : 'N/A';
            default:
                return value.toString();
        }
    };

    const handleStatusChange = async (product: BatchProduct | SingleProduct, newStatus: string) => {
        // Type guard to ensure we're working with a BatchProduct
        if (!isBatchProduct(product) || !batchName) return;
    
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://toolex.herweck.de/api/update-product-offer-status/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    EAN: product.EAN,
                    status: newStatus,
                    batch_name: batchName,
                }),
            });
    
            // If the request was successful, update the product in the state with an immutable update
            if (response.ok) {
                setFilteredProducts(prevProducts => {
                    return prevProducts.map(p => {
                        // We need to check if p is a BatchProduct first
                        if (isBatchProduct(p) && p.id === product.id && p.current_offer) {
                            return {
                                ...p,
                                current_offer: {
                                    ...p.current_offer,
                                    status: newStatus
                                }
                            } as T;
                        }
                        return p;
                    });
                });
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
        setActiveDropdown(null);
    };

    const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({
        status: true,
        Title: false,
        EAN: true,
        EK_Preis: false,
        Latest_Price_Amazon_EUR_Netto : false,
        Latest_Price_Amazon_EUR_Brutto : false,
        Herweck_Artikel_Nr: true,
        Herweck_Artikel_Vertreter: true,
        Manufacturer: true,
        Color: true,
        Volume: true,
        Amazon_Margin_Eur: true,
        Amazon_Margin_Procent: true,
        Idealo_Margin_Eur: true,
        Idealo_Margin_Procent: true,
        Galaxus_CH_Margin_Eur: false,
        Galaxus_DE_Margin_Eur: false,
        Online_Since: true,
        Monthly_Sold: true,
        Reviews: true,
        Review_Count: true,
        Sub_Category: true,
        Category_Rank: true,
        Total_Offers_Idealo: true,
        Score: true,
    });

    return (
        <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow">
            <div className="flex items-center space-x-4">
                {showToggle && (
                    <>
                        <span className="font-medium">
                            {showNotFound ? "Showing Incomplete Products" : "Showing Complete Products"}
                        </span>
                        <button
                            onClick={() => setShowNotFound(!showNotFound)}
                            className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                                showNotFound 
                                    ? 'bg-yellow-500 text-white hover:bg-yellow-600' 
                                    : 'bg-blue-500 text-white hover:bg-blue-600'
                            }`}
                        >
                            Toggle {showNotFound ? 'Complete' : 'Incomplete'} Products
                        </button>
                    </>
                )}
                <button
                    onClick={() => setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc')}
                    className="px-4 py-2 rounded-md bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-200"
                >
                    Score Order: {sortDirection === 'desc' ? '↓ High to Low' : '↑ Low to High'}
                </button>
                {showToggle && (
                    <div className="flex items-center space-x-2">
                        <label className="text-sm font-medium text-gray-700">
                            Min Score (0-10):
                        </label>
                        <input
                            type="number"
                            min="0"
                            max="10"
                            value={scoreFilter}
                            onChange={handleScoreFilterChange}
                            className="w-20 px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="e.g., 7"
                        />
                        {scoreFilter && (
                            <span className="text-sm text-gray-600">
                                (Showing ≥ {(parseInt(scoreFilter)/10).toFixed(1)})
                            </span>
                        )}
                        {scoreFilter && (
                            <button
                                onClick={() => setScoreFilter('')}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                ✕
                            </button>
                        )}
                    </div>
                )}
                
            </div>
            <div className="text-sm text-gray-600 flex flex-col items-end">
                <div>
                    Showing {filteredProducts.length} {showNotFound ? 'incomplete' : 'complete'} products
                </div>
                {filteredProducts.length > 0 && (
                    <div className="text-xs text-gray-500">
                        Score Range: {formatValue(Math.min(...filteredProducts.map(p => p.score)), 'number')} - {formatValue(Math.max(...filteredProducts.map(p => p.score)), 'number')}
                    </div>
                )}
            </div>
            {mode === 'batch' && (
                <>
                    {batchName !== undefined && (
                        <>
                            <BatchStatusSelector batchName={batchName} />
                            <ExcelExportButton
                                filteredProducts={filteredProducts}
                                batchName={batchName}
                                columnVisibility={columnVisibility}
                            />
                            {showToggle && (
                                <button
                                    onClick={handleGalaxusScraping}
                                    disabled={isProcessing}
                                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                                        isProcessing 
                                            ? 'bg-gray-400 cursor-not-allowed' 
                                            : 'bg-purple-500 text-white hover:bg-purple-600'
                                    }`}
                                >
                                    {isProcessing ? 'Processing Galaxus Data...' : 'Fetch Galaxus Data'}
                                </button>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
        <div className="relative">
    <button 
        onClick={() => setIsColumnSettingsOpen(!isColumnSettingsOpen)}
        className="bg-gray-100 p-2 rounded-md hover:bg-gray-200 flex items-center space-x-2"
    >
        <span>Column Settings</span>
        <svg 
            className={`w-4 h-4 transition-transform ${isColumnSettingsOpen ? 'rotate-180' : ''}`} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    </button>

    {isColumnSettingsOpen && (
    <ColumnToggle 
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        onClose={() => setIsColumnSettingsOpen(false)}
    />
)}
        </div>
        {/* Galaxus Processing */}
        {isProcessing && (
            <div className="text-center py-2 bg-purple-100 text-purple-700">
                Processing Galaxus data... This may take a few minutes.
            </div>
        )}

            {/* Table */}
            <div className="overflow-x-auto min-h-[1000px]">
                <table className="min-w-full text-xs text-gray-700 border-collapse">
                    <thead className="bg-gray-900 text-white top-0 z-50">
                    <tr>
                        {Object.entries(columnVisibility).map(([key, visible]) => (
                            <th 
                                key={key}
                                className={`${visible ? '' : 'hidden'} px-2 py-1 border-b border-gray-600`}
                            >
                                {key.replace(/_/g, ' ')}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                    {filteredProducts.map((product, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                            {Object.entries(columnVisibility).map(([key, visible]) => {

                            // Helper to get classification color for columns that need it
                            const getColumnClassification = () => {
                                switch(key) {
                                    case 'Color':
                                        return getClassificationColor(product.classifications?.color);
                                    case 'Volume':
                                        return getClassificationColor(product.classifications?.volume);
                                    case 'Amazon_Margin_Procent':
                                        return getClassificationColor(product.classifications?.amazon_margin);
                                    case 'Idealo_Margin_Procent':
                                        return getClassificationColor(product.classifications?.idealo_margin);
                                    case 'Online_Since':
                                        return getClassificationColor(product.classifications?.online_since);
                                    case 'Monthly_Sold':
                                        return getClassificationColor(product.classifications?.avg_monthly_sold);
                                    case 'Reviews':
                                        return getClassificationColor(product.classifications?.avg_review);
                                    case 'Review_Count':
                                        return getClassificationColor(product.classifications?.review_count);
                                    case 'Category_Rank':
                                        return getClassificationColor(product.classifications?.sub_category_rank);
                                    case 'Total_Offers_Idealo':
                                        return getClassificationColor(product.classifications?.total_offers_idealo);
                                    case 'Score':
                                        return product.score >= 0.7 ? 'bg-green-500 text-white' :
                                            product.score >= 0.4 ? 'bg-yellow-500 text-black' :
                                            'bg-red-500 text-white';
                                    default:
                                        return '';
                                }
                            };
                                return (
                                    <td 
                                        key={key}
                                        className={`${visible ? '' : 'hidden'} px-2 py-1 border-b border-gray-200 ${getColumnClassification()}`}
                                    >
                                        {getColumnContent(product, key, index)} {/* Pass index here */}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BatchTable;