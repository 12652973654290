import React, { useRef, useEffect } from 'react';

interface ColumnVisibility {
    [key: string]: boolean;
}

interface ColumnToggleProps {
    columnVisibility: ColumnVisibility;
    setColumnVisibility: (visibility: ColumnVisibility) => void;
    onClose: () => void;
    className?: string;
}

const ColumnToggle: React.FC<ColumnToggleProps> = ({
    columnVisibility,
    setColumnVisibility,
    onClose,
    className = ''
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm">
            <div 
                ref={containerRef}
                className={`bg-white p-6 rounded-xl shadow-xl w-full max-w-3xl mx-4 backdrop-blur-sm bg-opacity-100 border border-gray-200 ${className}`}
            >
                <h3 className="text-xl font-semibold mb-6 text-gray-800">Toggle Columns</h3>
                <div className="grid grid-cols-3 gap-4">
                    {Object.entries(columnVisibility).map(([key, value]) => (
                        <div 
                            key={key} 
                            className="flex items-center p-3 hover:bg-gray-50 rounded-lg cursor-pointer
                                     transition-colors duration-200 border border-gray-100"
                            onClick={() => setColumnVisibility({
                                ...columnVisibility,
                                [key]: !columnVisibility[key]
                            })}
                        >
                            <div 
                                className={`w-6 h-6 rounded-md border mr-3 flex items-center justify-center
                                    transition-colors duration-200
                                    ${value ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}
                            >
                                {value && (
                                    <svg 
                                        className="w-4 h-4 text-white" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth="2" 
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span className="text-sm font-medium text-gray-700">
                                {key.replace(/_/g, ' ')}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ColumnToggle;