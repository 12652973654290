import React, { useState, useEffect, useRef } from 'react';
import { BatchProductWithGalaxus } from '../types/interfaces';
import BatchTable from '../Batch Table/BatchTable';

interface GalaxusResult {
  batch_name: string;
  status: 'complete' | 'error';
  eans: string[];
  data?: BatchProductWithGalaxus[];
  error?: string;
}

const GalaxusProcessor = () => {
  const [batchName, setBatchName] = useState('');
  const [eans, setEans] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [results, setResults] = useState<GalaxusResult | null>(null);
  const [processedProducts, setProcessedProducts] = useState<BatchProductWithGalaxus[]>([]);
  const [status, setStatus] = useState('');
  const wsRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    wsRef.current = new WebSocket('wss://toolex.herweck.de/ws/galaxus-process/');

    wsRef.current.onopen = () => {
      console.log('WebSocket Connected');
      setStatus('Connected to WebSocket');
    };

    wsRef.current.onmessage = (event) => {
      const data: GalaxusResult = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);

      if (data.status === 'complete') {
        setStatus(`Batch ${data.batch_name} processed successfully!`);
        setIsProcessing(false);
        setResults(data);
        if (data.data) {
          setProcessedProducts(data.data);
        }
      } else if (data.status === 'error') {
        setStatus(`Error processing batch ${data.batch_name}: ${data.error}`);
        setIsProcessing(false);
        setResults(data);
      }
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setStatus('WebSocket connection error');
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket disconnected');
      setStatus('WebSocket disconnected');
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!batchName.trim() || !eans.trim()) {
      setStatus('Please fill in both batch name and EANs');
      return;
    }

    const eanList = eans.split('\n').map(ean => ean.trim()).filter(Boolean);
    
    if (eanList.length === 0) {
      setStatus('Please enter at least one EAN');
      return;
    }

    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        batch_name: batchName,
        eans: eanList,
      }));
      
      setIsProcessing(true);
      setResults(null);
      setProcessedProducts([]);
      setStatus('Processing started...');
    } else {
      setStatus('WebSocket not connected');
    }
  };

  const handleDataUpdate = (updatedData: BatchProductWithGalaxus[]) => {
    setProcessedProducts(updatedData);
  };

  return (
    <div>
      <h2>Galaxus Data Processor</h2>
      
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Batch Name:
            <input
              type="text"
              value={batchName}
              onChange={(e) => setBatchName(e.target.value)}
              placeholder="Enter batch name"
              disabled={isProcessing}
            />
          </label>
        </div>
        
        <div>
          <label>
            EANs (one per line):
            <textarea
              value={eans}
              onChange={(e) => setEans(e.target.value)}
              placeholder="Enter EANs, one per line"
              rows={5}
              disabled={isProcessing}
            />
          </label>
        </div>

        <button type="submit" disabled={isProcessing}>
          {isProcessing ? 'Processing...' : 'Process EANs'}
        </button>
      </form>

      {status && (
        <div>
          <h3>Status:</h3>
          <p>{status}</p>
        </div>
      )}

      {processedProducts.length > 0 && (
        <BatchTable<BatchProductWithGalaxus>
          data={processedProducts}
          mode="batch"
          batchName={batchName}
          notFoundData={[]}
          onDataUpdate={handleDataUpdate}
        />
      )}
    </div>
  );
};

export default GalaxusProcessor;